var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"web"},[_c('v-expansion-panels',{attrs:{"focusable":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((1),function(item,i){return _c('v-expansion-panel',{key:i,staticClass:"web__panel",model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel-header',[_c('div',{staticClass:"web__panelheader"},[_c('img',{staticClass:"web__panelimg",attrs:{"src":require("../assets/logos/logooc.png")}}),_c('div',{staticClass:"web__paneltitle"},[_vm._v("[2021 - 2022]")])])]),_c('v-expansion-panel-content',[_c('div',{staticClass:"web__intro"},[_c('v-card-text',{staticClass:"web__introtxt"},[_vm._v("Projets réalisés lors de la formation "),_c('a',{attrs:{"target":"_blank","href":"https://openclassrooms.com/fr/paths/556-developpeur-web"}},[_vm._v("Développeur Web")]),_vm._v(" d'"),_c('a',{attrs:{"target":"_blank","href":"https://openclassrooms.com/fr/"}},[_vm._v("Openclassrooms")]),_vm._v(" effectuée en alternance avec l'entreprise "),_c('a',{attrs:{"target":"_blank","href":"https://www.magikeduk.fr/"}},[_vm._v("Magik Éduk")])])],1),_c('div',{staticClass:"web__cards"},_vm._l((_vm.projects.slice(0, 3)),function(project){return _c('v-card',{key:project.id,staticClass:"web__card"},[_c('v-card-title',{staticClass:"web__title"},[_vm._v(_vm._s(project.title))]),_c('div',{staticClass:"web__image"},[_c('v-img',{staticClass:"web__img",attrs:{"src":project.image}})],1),_c('v-card-text',{staticClass:"web__subtitle"},[_vm._v(_vm._s(project.subtitle))]),_c('div',{staticClass:"web__bottom"},[_c('v-card-text',{staticClass:"web__skills"},[_vm._v("Compétences : "),_c('div',{staticClass:"web__icons"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('img',_vm._g({staticClass:"web__icon",attrs:{"src":project.icon1}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(project.icontext1))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('img',_vm._g({staticClass:"web__icon",attrs:{"src":project.icon2}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(project.icontext2))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-img',_vm._g({staticClass:"web__icon",attrs:{"src":project.icon3}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(project.icontext3))])])],1)]),_c('div',{staticClass:"web__link"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',{attrs:{"href":project.link,"target":"_blank"}},[_c('v-img',_vm._g({staticClass:"web__icon",attrs:{"src":require("../assets/icons/icongithub.png")}},on))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.linktext))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',{attrs:{"href":project.demo,"target":"_blank"}},[_c('v-img',_vm._g({staticClass:"web__icon2",attrs:{"href":project.demo,"src":project.iconweb}},on))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.linkdemo))])])],1)],1)],1)}),1),_c('div',{staticClass:"web__cards"},_vm._l((_vm.projects.slice(3, 6)),function(project){return _c('v-card',{key:project.id,staticClass:"web__card"},[_c('v-card-title',{staticClass:"web__title"},[_vm._v(_vm._s(project.title))]),_c('div',{staticClass:"web__image"},[_c('v-img',{staticClass:"web__img",attrs:{"src":project.image}})],1),_c('v-card-text',{staticClass:"web__subtitle"},[_vm._v(_vm._s(project.subtitle))]),_c('div',{staticClass:"web__bottom"},[_c('v-card-text',{staticClass:"web__skills"},[_vm._v("Compétences : "),_c('div',{staticClass:"web__icons"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('img',_vm._g({staticClass:"web__icon",attrs:{"src":project.icon1}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(project.icontext1))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('img',_vm._g({staticClass:"web__icon",attrs:{"src":project.icon2}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(project.icontext2))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-img',_vm._g({staticClass:"web__icon",attrs:{"src":project.icon3}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(project.icontext3))])])],1)]),_c('div',{staticClass:"web__link"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',{attrs:{"href":project.link,"target":"_blank"}},[_c('v-img',_vm._g({staticClass:"web__icon",attrs:{"src":require("../assets/icons/icongithub.png")}},on))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.linktext))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',{attrs:{"href":project.demo,"target":"_blank"}},[_c('v-img',_vm._g({staticClass:"web__icon2",attrs:{"href":project.demo,"src":project.iconweb}},on))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.linkdemo))])])],1)],1)],1)}),1)])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }